import React, { Component } from "react";
//le css
import "./LogoutConfirmation.css";
import $ from "jquery";

class LogoutConfirmation extends Component {
    componentDidMount() {
        const modal = $(`#${this.props.id}`);
        if (modal.modal) {
            modal.modal("show");
        }
    }

    modalDismissed(event) {
        if (this.props.onModalDismiss) {
            this.props.onModalDismiss();
        }
    }

    confirmLogout(event) {
        this.props.logoutConfirmed(event)
    }

    render() {
        return (
            <React.Fragment>
                <div
                    className="modal fade"
                    id={this.props.id}
                    tabIndex="-1"
                    role="dialog"
                    data-keyboard="false"
                    data-backdrop="static"
                    aria-labelledby="modalTitle"
                >
                    <div className="modal-dialog rwt-dialog" role="document">
                        <div className="modal-content rwt-modal-content">
                            <div className="modal-header rwt-modal-content">
                                <h5 className="modal-title rwt-modal-title" id="modalTitle">
                                    Logout confirmation
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={e => this.modalDismissed(e)}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body rwt-modal-body">
                                Are you sure you want to log out?
                            </div>
                            <div className="modal-footer rwt-modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary rwt-modal-btn no-btn"
                                    data-dismiss="modal"
                                    onClick={e => this.modalDismissed(e)}
                                >
                                    No
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary rwt-modal-btn yes-btn"
                                    data-dismiss="modal"
                                    onClick={e => this.confirmLogout(e)}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LogoutConfirmation;
