function formatCents(cents) {
  let dollars = cents / 100;
  return dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
}

function buildItemFromMenuItem(menuItem, quantity) {
  const item = {
    quantity: quantity,
    menuItemId: menuItem.id,
    name: menuItem.name,
    price: menuItem.price,
    tax: menuItem.tax,
    // does not round because round has to be done after the sum
    // e.g. 9% of 350 == 31.5
    // taxes for 4 items should be 126 == 4*31.5 and not 124 (31*4) or 128 (32*4)
    computeTaxesInCents: function() {
      return (this.quantity * this.price * this.tax) / 100;
    },
    computeSubTotalInCents: function() {
      return this.quantity * this.price;
    }
  };

  return item;
}

export { formatCents, buildItemFromMenuItem };
