import React, { Component } from "react";
import NavBar from "../Navbar";
import RwtContainer from "../RwtContainer";
import Categories from "../Tab/Categories";
import Details from "../Tab/Details";
import MenuItemList from "../Tab/MenuItemList";
import Modal from "../Modal";
import { buildItemFromMenuItem } from "../../utils/TabUtils";
//le css
import "./Tab.css";

class Tab extends Component {
  constructor(props) {
    super(props);
    const categories = this.props.menu.categories;
    let selectedCategory = null;
    if (categories && categories.length > 0) {
      selectedCategory = categories[0];
    }
    this.state = {
      items: [], // items added or removed from tab
      loading: false,
      selectedCategory: selectedCategory,
      error: null,
      tab: this.props.tab // current tab
    };
  }

  onItemAdded(event, menuItem) {
    console.log(menuItem);
    const items = [...this.state.items];
    let item = items.find(i => i.menuItemId === menuItem.id);
    if (item == null || item === undefined) {
      item = buildItemFromMenuItem(menuItem, 1);
      this.setState({ items: [...items, item] });
    } else {
      item.quantity++;
      if (item.quantity === 0) {
        this.setState({ items: items.filter(i => i !== item) });
      } else {
        this.setState({ items: items });
      }
    }
  }

  onItemRemoved(event, menuItem) {
    console.log(menuItem);
    const items = [...this.state.items];
    const tab = this.state.tab;
    let item = items.find(i => i.menuItemId === menuItem.id);
    let tabItem = null;
    if (tab.items) {
      tabItem = tab.items.find(i => i.menuItemId === menuItem.id);
    }

    if (item === null || item === undefined) {
      if (tabItem == null || tabItem === undefined) {
        const error = { message: "Cannot void an item that doesn't exist." };
        this.setState({ error: error });
        event.stopPropagation();
        return;
      } else {
        item = buildItemFromMenuItem(menuItem, -1);
        this.setState({ items: [...items, item] });
      }
    } else {
      if (item.quantity < 0 && tabItem.quantity === -item.quantity) {
        const error = {
          message:
            "Cannot void more than " + tabItem.quantity + " " + item.name + "."
        };
        this.setState({ error: error });
        event.stopPropagation();
        return;
      }
      item.quantity--;
      if (item.quantity === 0) {
        this.setState({ items: items.filter(i => i !== item) });
      } else {
        this.setState({ items: items });
      }
    }

    event.stopPropagation();
  }

  onCategorySelected(event, category) {
    const selectedCategory = this.state.selectedCategory;
    if (selectedCategory === null || selectedCategory.id !== category.id) {
      this.setState({ selectedCategory: category });
    }
  }

  onSendItems(event) {
    event.preventDefault();
    this.setState({ loading: true, error: null });
    if (this.state.items === null || this.state.items.length === 0) {
      // No items, same as cancelling
      this.props.onCancelTab(event);
    } else {
      const api = this.props.rwtApi;
      api
        .sendItems(this.props.tab.ticketNumber, this.state.items)
        .then(result => this.handleSendItemsSuccess(result))
        .catch(error => this.handleSendItemsError(error));
    }
  }

  handleSendItemsSuccess(result) {
    this.setState({ loading: false, error: null });
    this.props.onItemsSent(result);
    console.log(result);
  }

  handleSendItemsError(e) {
    const error = { message: null };
    if (e.response) {
      const status = e.response.status;
      const data = e.response.data;
      if (status === 404) {
        error.message = data.message ? data.message : "Ticket not found";
      } else if (status === 500) {
        error.message = data.message
          ? data.message
          : "Server error - Please try again";
        if (data.tab) {
          this.setState({ tab: data.tab, items: [] });
        } else {
          // should we get the tab again or force the user to reopen it?
        }
      } else if (status === 401) {
        //TODO how can we improve this error messaging/handling?
        error.message = (
          <div>
            <p>
              Ticket No. {this.state.tab.ticketNumber} could not be updated
              because your session is no longer valid.
            </p>
            <p>Please sign out or refresh this window and try again.</p>
          </div>
        );
      }
    }

    if (error.message === null) {
      error.message = "An error has occurred - Please try again";
    }

    this.setState({ loading: false, error: error });
  }

  getMenuItems() {
    const selectedCategory = this.state.selectedCategory;
    const menu = this.props.menu;
    if (selectedCategory === null) {
      return menu.menuItems;
    } else {
      return menu.menuItems.filter(
        m => m.categories && m.categories.includes(selectedCategory.id)
      );
    }
  }

  getCategories() {
    const categories = this.props.menu.categories;
    return categories;
  }

  onErrorModalDismiss() {
    this.setState({ error: null });
  }

  render() {
    const tab = this.state.tab;
    const error = this.state.error;

    return (
      <React.Fragment>
        <NavBar name={this.props.loggedInName}>
          <button
            className="btn btn-navbar-right"
            onClick={e => this.props.onCancelTab(e)}
          >
            Cancel
          </button>
        </NavBar>
        <RwtContainer className="rwt-container-tab mobileNav">
          <Categories
            categories={this.getCategories()}
            selectedCategory={this.state.selectedCategory}
            onCategorySelected={(e, category) =>
              this.onCategorySelected(e, category)
            }
          />
          <MenuItemList
            menuItems={this.getMenuItems()}
            onItemAdded={(e, menuItem) => this.onItemAdded(e, menuItem)}
            onItemRemoved={(e, menuItem) => this.onItemRemoved(e, menuItem)}
          />
          <Details
            tab={tab}
            items={this.state.items}
            loading={this.state.loading}
            onSendItems={e => this.onSendItems(e)}
          />
        </RwtContainer>
        {error && (
          <Modal
            title="Error"
            id="errorDialog"
            onModalDismiss={e => this.onErrorModalDismiss(e)}
          >
            {error.message}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default Tab;
