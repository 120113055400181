import React, { Component } from "react";

class RwtContainer extends Component {
  render() {
    let style = {};
    if (this.props.style) {
      style = this.props.style;
    }

    let className = "container-fluid rwtContainer";
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }

    return (
      <div className={className} style={style}>
        <div className="row no-gutters fullHeight">{this.props.children}</div>
      </div>
    );
  }
}

export default RwtContainer;
