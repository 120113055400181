import React, { Component } from "react";
import RwtContainer from "../RwtContainer";
//le css
import "./Keypad.css";
import loadingImg from "../../static/load.gif";

class Keypad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: ""
    };
  }

  updateNumberValue(e) {
    this.setState({
      number: e.target.value
    });
  }

  keypadNumberClicked(value) {
    if (this.props.error) {
      this.setState({
        number: value
      });
    } else {
      const number = this.state.number;
      this.setState({
        number: `${number}${value}`
      });
    }

    if (this.props.keypadNumberClicked) {
      this.props.keypadNumberClicked(value);
    }
  }

  keypadBackspaceClicked() {
    if (this.props.error) {
      this.setState({
        number: ""
      });
    } else {
      const number = this.state.number;
      if (number) {
        this.setState({
          number: number.slice(0, -1)
        });
      }
    }

    if (this.props.keypadBackspaceClicked) {
      this.props.keypadBackspaceClicked();
    }
  }

  keypadDeleteClicked() {
    this.setState({
      number: ""
    });

    if (this.props.keypadDeleteClicked) {
      this.props.keypadDeleteClicked();
    }
  }

  send(e) {
    const sendButton = this.props.sendButton;
    sendButton.onClick(e, this.state.number);
  }

  render() {
    const loading = this.props.loading;
    const label = this.props.label;
    const display = this.props.display;
    const sendButton = this.props.sendButton;

    return (
      <RwtContainer className="rwt-container-keypad">
        <div className="col-md-12 fullHeight">
          <div className="middleWrap">
            <div className="middleContent">
              <div id={this.props.id} className="keypad-container">
                <div className={label.className}>{label.text}</div>
                <input
                  name="displayNumber"
                  id="displayNumber"
                  type={display.type}
                  readOnly="readonly"
                  value={this.state.number}
                  placeholder={display.placeholder}
                  onChange={e => this.updateNumberValue(e)}
                  className={display.className}
                />
                <table className="keypad">
                  <tbody>
                    <tr>
                      <td>
                        <button
                          className="btn number"
                          onClick={e => this.keypadNumberClicked("1")}
                        >
                          1
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn number"
                          onClick={e => this.keypadNumberClicked("2")}
                        >
                          2
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn number"
                          onClick={e => this.keypadNumberClicked("3")}
                        >
                          3
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          className="btn number"
                          onClick={e => this.keypadNumberClicked("4")}
                        >
                          4
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn number"
                          onClick={e => this.keypadNumberClicked("5")}
                        >
                          5
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn number"
                          onClick={e => this.keypadNumberClicked("6")}
                        >
                          6
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          className="btn number"
                          onClick={e => this.keypadNumberClicked("7")}
                        >
                          7
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn number"
                          onClick={e => this.keypadNumberClicked("8")}
                        >
                          8
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn number"
                          onClick={e => this.keypadNumberClicked("9")}
                        >
                          9
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          className="btn number extra"
                          onClick={e => this.keypadDeleteClicked()}
                        >
                          <i className="fa fa-sync-alt fa-fw" />
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn number"
                          onClick={e => this.keypadNumberClicked("0")}
                        >
                          0
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn number extra"
                          onClick={e => this.keypadBackspaceClicked()}
                        >
                          <i className="fas fa-backspace" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        {loading ? (
                          <button
                            id="btnSend"
                            className="btn btn-block"
                            disabled="disabled"
                            type="submit"
                          >
                            <img
                              src={loadingImg}
                              alt="loading"
                              style={{ width: 27, height: 27 }}
                            />
                          </button>
                        ) : (
                          <button
                            id="btnSend"
                            className="btn btn-block"
                            type="submit"
                            onClick={e => this.send(e)}
                          >
                            {sendButton.text}
                          </button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </RwtContainer>
    );
  }
}

export default Keypad;
