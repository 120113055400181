import React, { Component } from "react";
import { formatCents } from "../../../utils/TabUtils";
import load from "../../../static/load.gif";
//le css
import "./Details.css";

class Details extends Component {
  onSendItems(event) {
    this.props.onSendItems(event);
  }

  newItemsSubTotal() {
    return this.props.items
      .map(i => i.computeSubTotalInCents())
      .reduce((a, b) => a + b, 0);
  }

  newItemsTax() {
    let taxes = this.props.items
      .map(i => i.computeTaxesInCents())
      .reduce((a, b) => a + b, 0);
    if (taxes >= 0) {
      return Math.round(taxes);
    } else {
      return -Math.round(Math.abs(taxes));
    }
  }

  newItemsTotal() {
    return this.newItemsSubTotal() + this.newItemsTax();
  }

  render() {
    const { tab, items, loading } = this.props;

    return (
      <div id="details" className="col-md-4">
        <div id="detailsHeader">
          <span>Ticket No: {tab.ticketNumber}</span>
          <span>Name: {tab.name}</span>
        </div>
        <div id="detailsItem">
          {items.length > 0 && (
            <React.Fragment>
              <ul className="details">
                {items.map(item => (
                  <li key={item.menuItemId}>
                    <span>
                      {item.quantity} x {item.name}
                    </span>
                    <span className="float-right">
                      {formatCents(item.computeSubTotalInCents())}
                    </span>
                  </li>
                ))}
              </ul>
              <hr />
            </React.Fragment>
          )}
          {tab.items !== null && tab.items.length > 0 && (
            <React.Fragment>
              <ul className="details">
                {tab.items.map(item => (
                  <li key={item.id}>
                    <span>
                      {item.quantity} x {item.name}
                    </span>
                    <span className="float-right">
                      {formatCents(item.price)}
                    </span>
                  </li>
                ))}
              </ul>
              <hr />
            </React.Fragment>
          )}
          <ul className="details totals">
            <li>
              <span>Subtotal:</span>
              <span className="float-right subTotal">
                {formatCents(tab.totals.subTotal + this.newItemsSubTotal())}
              </span>
            </li>
            <li>
              <span>Tax:</span>
              <span className="float-right tax">
                {formatCents(
                  tab.totals.tax + this.newItemsTax()
                ) /*FIXME: this may not be correct because of rounding errors */}
              </span>
            </li>
            <li>
              <span>Total:</span>
              <span className="float-right total">
                {formatCents(tab.totals.total + this.newItemsTotal())
                /*FIXME: this may not be correct because of rounding errors */
                }
              </span>
            </li>
          </ul>
        </div>
        <div id="detailsFooter">
          {loading ? (
            <button
              className="btn btn-block btn-send-items"
              disabled="disabled"
            >
              <img
                src={load}
                alt="sending"
                id="sendingItems"
                style={{ width: 20, height: 20 }}
              />
            </button>
          ) : (
            <button
              className="btn btn-block btn-send-items"
              onClick={e => this.onSendItems(e)}
            >
              {items && items.length
                ? `Send ${formatCents(tab.totals.total + this.newItemsTotal())}`
                : "Send"}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default Details;
