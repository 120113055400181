import React, { Component } from "react";
import Keypad from "../Keypad";
import NavBar from "../../Navbar";
import RwtApi from "../../../rwt-api";

import "./LoginKeypad.css";

class LoginKeypad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false
    };
  }

  onSend(event, number) {
    if (number === null || number === undefined || number.trim().length === 0) {
      this.setState({
        loading: false,
        error: { message: "Please enter a user number" }
      });
      event.preventDefault();
      return;
    }

    this.setState({ loading: true });
    const api = new RwtApi();
    api
      .loginWithCode(number)
      .then(result => this.handleLoginSuccess(result))
      .catch(error => this.handleLoginError(error));
    event.preventDefault();
  }

  handleLoginSuccess(result) {
    this.setState({ loading: false });
    this.props.onLogin(result.data.token);
  }

  handleLoginError(e) {
    const error = { message: null };
    if (e.response) {
      const status = e.response.status;
      const data = e.response.data;
      if (status === 401) {
        error.message = data.message
          ? data.message
          : "Wrong number - Please try again";
      } else if (status === 500) {
        error.message = data.message
          ? data.message
          : "Server error - Please try again";
      }
    }

    if (error.message === null) {
      error.message = "An error has occurred - Please try again";
    }

    this.setState({ loading: false, error: error });
  }

  getKeypayLabel() {
    if (this.state.error === null) {
      return "Enter user number";
    } else {
      return this.state.error.message;
    }
  }

  getKeypadLabelClassName() {
    let cssClasses = "font-weight-normal keypad-label";
    if (this.state.error !== null) {
      cssClasses += " keypad-label-error";
    }

    return cssClasses;
  }

  getDisplayNumberClassName() {
    let cssClasses = "form-control display-number";
    if (this.state.error !== null) {
      cssClasses += " display-number-error";
    }

    return cssClasses;
  }

  keypadNumberClicked(value) {
    if (this.state.error) {
      this.setState({ error: null });
    }
  }

  keypadBackspaceClicked() {
    if (this.state.error) {
      this.setState({ error: null });
    }
  }

  keypadDeleteClicked() {
    if (this.state.error) {
      this.setState({ error: null });
    }
  }

  render() {
    return (
      <React.Fragment>
        <NavBar />
        <Keypad
          id="loginKeypad"
          label={{
            text: this.getKeypayLabel(),
            className: this.getKeypadLabelClassName()
          }}
          display={{
            type: "password",
            className: this.getDisplayNumberClassName(),
            placeholder: ""
          }}
          loading={this.state.loading}
          sendButton={{
            text: "Enter",
            onClick: (e, num) => this.onSend(e, num)
          }}
          keypadNumberClicked={value => this.keypadNumberClicked(value)}
          keypadDeleteClicked={() => this.keypadDeleteClicked()}
          keypadBackspaceClicked={() => this.keypadBackspaceClicked()}
          error={this.state.error}
        />
      </React.Fragment>
    );
  }
}

export default LoginKeypad;
