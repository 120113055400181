import React, { Component } from "react";
import RwtApi from "../../rwt-api";
//modules
import Tab from "../Tab";
import Loading from "../Loading";
import { TicketKeypad, LoginKeypad } from "../Keypad";
import * as constants from "../../constants";
import JsonWebToken from "jsonwebtoken";
//le css
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: this.getExistingToken(),
      menu: null,
      tab: null,
      error: null
    };
  }

  componentDidMount() {}

  getExistingToken() {
    return sessionStorage.getItem(constants.TOKEN_KEY);
  }

  saveToken(token) {
    this.setState({ token: token });
    sessionStorage.setItem(constants.TOKEN_KEY, token);
  }

  loadMenu() {
    const api = new RwtApi(this.state.token);
    api
      .getMenu()
      .then(result => this.handleLoadMenuSuccess(result))
      .catch(error => this.handleLoadMenuError(error));
  }

  handleLoadMenuSuccess(result) {
    this.setState({ menu: result.data });
  }

  handleLoadMenuError(e) {
    const error = { message: null };
    if (e.response) {
      const status = e.response.status;
      const data = e.response.data;
      const message = data.message ? data.message : "Menu not found";
      const footer = data.footer ? data.footer : "Please try again";
      if (status === 404) {
        error.message = this.getErrorMessage(
          message ? message : "Menu not found",
          footer
        );
      } else if (status === 500) {
        error.message = this.getErrorMessage(
          message ? message : "Server Error",
          footer
        );
      } else if (status === 401) {
        //force a sign out
        this.onSignOut();
        return;
      }
    }

    if (error.message === null) {
      error.message = this.getErrorMessage(
        "Oops! Something went wrong",
        "Please try again"
      );
    }

    this.setState({ error: error });
  }

  getErrorMessage(message, footer) {
    return (
      <React.Fragment>
        <i className="fas fa-exclamation-circle" id="loadingErrorRwtIcon" />
        <div id="errorLoadingRwtMessage">{message}</div>
        <div style={{ color: "#949599" }}>{footer}</div>
      </React.Fragment>
    );
  }

  onLogin(token) {
    this.saveToken(token);
  }

  onTabOpened(tab) {
    this.setState({ tab: tab });
      this.loadMenu();
  }

  onCancelTab(event) {
    console.log("Cancel tab");
    this.setState({ tab: null });
  }

  onItemsSent(result) {
    console.log("Items sent");
    console.log(result);
    this.setState({ tab: null });
  }

  onSignOut() {
    sessionStorage.removeItem(constants.TOKEN_KEY);
    this.setState({ token: null, menu: null });
  }

  shouldTokenBeRenewed(token) {
    //TODO should we use verify?
    const decodedToken = JsonWebToken.decode(token);
    if (decodedToken.created) {
      const now = new Date().getTime();
      const diff = Math.abs(now - decodedToken.created);
      return diff >= constants.MAX_TOKEN_AGE_IN_MILLIS;
    }
    return true;
  }

  getNameFromToken(token) {
    const decodedToken = JsonWebToken.decode(token);
    if (decodedToken.fullName) {
      return decodedToken.fullName;
    } else {
      return decodedToken.sub;
    }
  }

  render() {
    const { token, menu, tab } = this.state;

    if (token === null || this.shouldTokenBeRenewed(token)) {
      return <LoginKeypad onLogin={token => this.onLogin(token)} />;
    }

    const error = this.state.error;
    if (error) {
      return <Loading onSignOut={e => this.onSignOut(e)} error={error} />;
    }

    if (menu == null) {
      this.loadMenu();
      return <Loading onSignOut={e => this.onSignOut(e)} />;
    }

    const loggedInName = this.getNameFromToken(token);

    const rwtApi = new RwtApi(token);
    if (tab === null) {
      return (
        <TicketKeypad
          loggedInName={loggedInName}
          onTabOpened={tab => this.onTabOpened(tab)}
          onSignOut={e => this.onSignOut(e)}
          rwtApi={rwtApi}
        />
      );
    }

    return (
      <Tab
        loggedInName={loggedInName}
        menu={menu}
        tab={tab}
        onCancelTab={e => this.onCancelTab(e)}
        onItemsSent={result => this.onItemsSent(result)}
        rwtApi={rwtApi}
      />
    );
  }
}

export default App;
