import React, { Component } from "react";
import logo from "../../static/logo.png";

//le css
import "./Navbar.css";

class NavBar extends Component {
  render() {
    return (
      <nav className="navbar navbar-dark sticky-top flex-md-nowrap p-0">
        <a className="navbar-brand" href="#home">
          <div className="logo">
            <div className="icon">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </a>
        <span className="navbar-user-name">{this.props.name}</span>
        <ul className="nav justify-content-end">
          <li className="nav-item"> {this.props.children}</li>
        </ul>
      </nav>
    );
  }
}

export default NavBar;
