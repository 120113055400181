import { RWT_BACKEND } from "./rwt_backend.js";
import {removeEndpoint} from "./rwt_backend";
export const APP_VERSION = (function() {
  if (process.env.REACT_APP_RWT_VERSION) {
    return process.env.REACT_APP_RWT_VERSION;
  } else {
    return "development";
  }
})();

export const RELEASE_STAGE = (function() {
  if (process.env.REACT_APP_RWT_RELEASE_STAGE) {
    return process.env.REACT_APP_RWT_RELEASE_STAGE;
  } else {
    return "development";
  }
})();

export const SERVER_URL = RWT_BACKEND;
export const TOKEN_KEY = "AUTH_TOKEN";
export const SAMPLE_TOKEN =
  "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJiYXJ0ZW5kZXJAZXhhbXBsZS5jb20iLCJjcmVhdGVkIjoxNTM0NzEzMTcyMjk0LCJleHAiOjE1MzQ3OTk1NzJ9.AnFghde3d96SCsIM5PLEqkUqbcfQzANswS_phb9SpfElMKA4pBanCBUAUS3m2p5DIPHfcFpQtJklZGcAEjY5Ag";
export const MILLIS_IN_A_DAY = 86400000;
export const MAX_TOKEN_AGE_IN_MILLIS = MILLIS_IN_A_DAY * 7;
export const REMOVE_ENDPOINT= removeEndpoint
export const SAMPLE_TAB = {
  ticketNumber: "2199",
  ticketId: "3344",
  sessionId: "5b7743a93219a13021c62d39",
  name: "RUIZ J. 927",
  totals: { subTotal: 1950, tax: 176, total: 2126, due: 2126 },
  items: [
    {
      name: "Prairie Vodka",
      id: "4wlLwm7R",
      menuItemId: "10010",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "Vdp7PYA7",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "wQpDAY3E",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    }
  ]
};
export const SAMPLE_MENU = {
  menuItems: [
    {
      id: "10008",
      name: "Absolute Peppar",
      price: 650,
      categories: ["f201"]
    },
    {
      id: "10010",
      name: "Prairie Vodka",
      price: 650,
      categories: ["f201"]
    },
    {
      id: "10028",
      name: "Grey Goose",
      price: 775,
      categories: ["f201"]
    },
    { id: "8160", name: "Bud Lt  Btl", price: 250, categories: ["m3"] },
    { id: "8164", name: "Coor Lt Btl", price: 350, categories: ["m3"] },
    { id: "8173", name: "Miller Lt Btl", price: 350, categories: ["m3"] },
    { id: "8174", name: "Pilsen'i", price: 350, categories: ["m3"] },
    { id: "8175", name: "Conti", price: 350, categories: ["m3"] },
    { id: "8176", name: "Polar", price: 350, categories: ["m3"] },
    { id: "8177", name: "Labatt's Blue", price: 350, categories: ["m3"] },
    { id: "8178", name: "Corona", price: 350, categories: ["m3"] },
    { id: "8179", name: "Patagonia Amber", price: 350, categories: ["m3"] },
    { id: "8180", name: "Heineken", price: 350, categories: ["m3"] },
    { id: "8181", name: "Stella Artois", price: 350, categories: ["m3"] },
    { id: "8182", name: "Brahma", price: 350, categories: ["m3"] },
    { id: "8183", name: "Skol", price: 350, categories: ["m3"] },
    { id: "8184", name: "Paragonia 24/7", price: 350, categories: ["m3"] },
    { id: "8184", name: "Carlsberg", price: 350, categories: ["m3"] }
  ],
  categories: [{ id: "f201", name: "Liquor" }, { id: "m3", name: "Beer" }]
};
export const SAMPLE_TAB_2 = {
  ticketNumber: "2212",
  ticketId: "3357",
  sessionId: "5b7d85a0a101057bfb2ee80f",
  name: "PRIETO M. 978",
  totals: { subTotal: 13750, tax: 1238, total: 14988, due: 14988 },
  items: [
    {
      name: "Absolute Peppar",
      id: "19",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "20",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "21",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "22",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "23",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "24",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "25",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "26",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "27",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "28",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Absolute Peppar",
      id: "29",
      menuItemId: "10008",
      quantity: 1,
      price: 650
    },
    {
      name: "Miller Lt Btl",
      id: "30",
      menuItemId: "8173",
      quantity: 1,
      price: 350
    },
    {
      name: "Miller Lt Btl",
      id: "31",
      menuItemId: "8173",
      quantity: 1,
      price: 350
    },
    {
      name: "Miller Lt Btl",
      id: "32",
      menuItemId: "8173",
      quantity: 1,
      price: 350
    },
    {
      name: "Miller Lt Btl",
      id: "33",
      menuItemId: "8173",
      quantity: 1,
      price: 350
    },
    {
      name: "Miller Lt Btl",
      id: "34",
      menuItemId: "8173",
      quantity: 1,
      price: 350
    },
    {
      name: "Grey Goose",
      id: "35",
      menuItemId: "10028",
      quantity: 1,
      price: 775
    },
    {
      name: "Grey Goose",
      id: "36",
      menuItemId: "10028",
      quantity: 1,
      price: 775
    },
    {
      name: "Grey Goose",
      id: "37",
      menuItemId: "10028",
      quantity: 1,
      price: 775
    },
    {
      name: "Grey Goose",
      id: "38",
      menuItemId: "10028",
      quantity: 1,
      price: 775
    },
    {
      name: "Miller Lt Btl",
      id: "39",
      menuItemId: "8173",
      quantity: 1,
      price: 350
    },
    {
      name: "Miller Lt Btl",
      id: "40",
      menuItemId: "8173",
      quantity: 1,
      price: 350
    },
    {
      name: "Miller Lt Btl",
      id: "41",
      menuItemId: "8173",
      quantity: 1,
      price: 350
    },
    {
      name: "Miller Lt Btl",
      id: "42",
      menuItemId: "8173",
      quantity: 1,
      price: 350
    },
    {
      name: "Coor Lt Btl",
      id: "43",
      menuItemId: "8164",
      quantity: 1,
      price: 350
    }
  ]
};
