import React, { Component } from "react";
//le css
import "./MenuItem.css";

class MenuItem extends Component {
  render() {
    const { menuItem } = this.props;
    return (
      <div style={{ margin: 10 }}>
        <div
          onClick={e => this.props.onItemAdded(e, menuItem)}
          id={`menuItem_${this.props.menuItem.id}`}
          className="btn btn-rooam-add"
          style={{ width: "100%" }}
        >
          {menuItem.name}
          <button
            onClick={e => this.props.onItemRemoved(e, menuItem)}
            className="btn btn-rooam-delete"
          >
            <i className="fas fa-times" />
          </button>
        </div>
      </div>
    );
  }
}

export default MenuItem;
