import React, { Component } from "react";
//le css
import "./Modal.css";
import $ from "jquery";

class Modal extends Component {
  componentDidMount() {
    $(`#${this.props.id}`).modal("show");
  }

  modalDismissed(event) {
    if (this.props.onModalDismiss) {
      this.props.onModalDismiss();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="modal fade"
          id={this.props.id}
          tabIndex="-1"
          role="dialog"
          data-keyboard="false"
          data-backdrop="static"
          aria-labelledby="modalTitle"
        >
          <div className="modal-dialog rwt-dialog" role="document">
            <div className="modal-content rwt-modal-content">
              <div className="modal-header rwt-modal-content">
                <h5 className="modal-title rwt-modal-title" id="modalTitle">
                  {this.props.title}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={e => this.modalDismissed(e)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body rwt-modal-body">
                {this.props.children}
              </div>
              <div className="modal-footer rwt-modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary rwt-modal-btn"
                  data-dismiss="modal"
                  onClick={e => this.modalDismissed(e)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Modal;
