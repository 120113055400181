import React, { Component } from "react";

import NavBar from "../Navbar";
import RwtContainer from "../RwtContainer";
import "./Loading.css";
import loading from "../../static/load.gif";

class Loading extends Component {
  render() {
    const error = this.props.error;
    return (
      <React.Fragment>
        <NavBar>
          <button
            className="btn btn-navbar-right"
            onClick={e => this.props.onSignOut(e)}
          >
            Sign Out
          </button>
        </NavBar>
        <RwtContainer className="rwt-container-loading">
          <div className="offset-md-3 col-md-6">
            <div
              className="text-center"
              style={{ top: "30%", width: "100%", position: "absolute" }}
            >
              {!error && (
                <React.Fragment>
                  <img src={loading} alt="loading" id="loadingRwtImage" />

                  <div id="loadingRwtMessage">Loading Rooam Web Terminal</div>

                  <div style={{ color: "#949599" }}>
                    Everything will be up &amp; running in a moment
                  </div>
                </React.Fragment>
              )}
              {error && error.message}
            </div>
          </div>
        </RwtContainer>
      </React.Fragment>
    );
  }
}

export default Loading;
