import React, { Component } from "react";
//le css
import "./Categories.css";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: this.props.selectedCategory
    };
  }

  getClassesForCategory(category) {
    const selectedCategory = this.state.selectedCategory;
    if (selectedCategory && selectedCategory.id === category.id) {
      return "nav-link active";
    }

    return "nav-link";
  }

  onCategorySelected(e, category) {
    const selectedCategory = this.state.selectedCategory;
    if (selectedCategory === null || selectedCategory.id !== category.id) {
      this.setState({ selectedCategory: category });
    }
    /* else {
      this.setState({ selectedCategory: null });
    }*/

    this.props.onCategorySelected(e, category);
  }

  render() {
    return (
      <nav className="col-md-2 d-md-block sidebar categories">
        <div className="sidebar-sticky">
          <ul className="nav flex-column">
            {this.props.categories.map(category => (
              <li key={category.id} className="nav-item">
                <a
                  className={this.getClassesForCategory(category)}
                  href={`#${category.id}`}
                  onClick={e => this.onCategorySelected(e, category)}
                >
                  {category.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Categories;
