export const RWT_BACKEND = (function () {
	if (ENDPOINTS.length < 1) {
		ENDPOINTS = initEndpoints();
	}
	let array = [];
	for (let i = 0; i < ENDPOINTS.length; i += 2) {
		if (ENDPOINTS[i + 1] < Date.now()) {

			array.push(ENDPOINTS[i])
		}
	}
	return array[Math.floor(Math.random() * array.length)]
});

var ENDPOINTS = [];

export const initEndpoints =
	(function () {
		if (process.env.REACT_APP_RWT_BACKEND) {
			return [process.env.REACT_APP_RWT_BACKEND, 0];
		}
		if (window.RWT_BACKEND_ARRAY) {
			let array = [];
			for (let i = 0; i < window.RWT_BACKEND_ARRAY.length; ++i) {
				array.push(window.RWT_BACKEND_ARRAY[i]);
				array.push(0);
			}
			return array

		}
		if (window.RWT_BACKEND) {
			return [window.RWT_BACKEND, 0];
		}
		// --- staging --- //
		return ["https://staging-rwt-0.blu3f1n.com", 0];
	});

export const removeEndpoint = (function (url) {
	for (let i = 0; i < ENDPOINTS.length; i+=2) {
		if (ENDPOINTS[i]===url){
			ENDPOINTS[i+1]=Date.now()+10000;
		}

	}

});