import React, {Component} from "react";
import MenuItem from "./MenuItem/MenuItem";
//le css
import "./MenuItemList.css";

class MenuItemList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredMenuItems: props.menuItems,
            searchValue: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            filteredMenuItems: nextProps.menuItems,
            searchValue: ''
        })
    }

    handleChange(e) {
        let currentList = [];
        let newList = [];

        // If the search bar isn't empty
        if (e.target != null || e.target.value !== "") {
            this.setState({searchValue: e.target.value});
            currentList = this.props.menuItems;

            newList = currentList.filter(item => {
                const lc = item.name.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
        } else {
            newList = this.props.items;
        }
        this.setState({
            filteredMenuItems: newList

        });
    }

    clearSearch(e) {
        this.setState({
            filteredMenuItems: this.props.menuItems,
            searchValue: ''
        })
    }

    render() {
        return (
            <div className="col-md-6 item-list">
                <div id="menuItemList">
                    <div className="search-wrap">
                        <form id="search-item-form">
                            <div className="form-group mb-0">
                                <input type="text" value={this.state.searchValue} onChange={this.handleChange}
                                       className="form-control" id="search-item-input"
                                       onKeyPress={e => {
                                           if (e.key === 'Enter') {
                                               e.preventDefault();
                                           }
                                       }}
                                       placeholder="Search for item..."/>
                                <span onClick={this.clearSearch} className="form-clear"><i
                                    className="fas fa-times-circle"/></span>
                            </div>
                        </form>
                    </div>
                    {this.state.filteredMenuItems.map(menuItem => (
                        <MenuItem
                            key={menuItem.id}
                            menuItem={menuItem}
                            onItemAdded={this.props.onItemAdded}
                            onItemRemoved={this.props.onItemRemoved}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default MenuItemList;
