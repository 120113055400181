/* eslint-disable */
import { APP_VERSION, RELEASE_STAGE, SERVER_URL } from "./constants";
import bugsnag from "bugsnag-js";
const bugsnagClient = bugsnag({
  apiKey: "5c7af9f0031282bfb2a02f6c52420d99",
  appVersion: APP_VERSION,
  releaseStage: RELEASE_STAGE
});

import React from "react";
import ReactDOM from "react-dom";
import createPlugin from "bugsnag-react";

import App from "./components/App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
/* eslint-enable */
console.log(
  "Running RWT version: " +
    APP_VERSION +
    " - " +
    RELEASE_STAGE +
    "; RWT Backend: " +
    SERVER_URL()
);

const ErrorBoundary = bugsnagClient.use(createPlugin(React));
ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);
// bugsnagClient.notify(new Error("Test error"));

if (module.hot) {
  module.hot.accept();
}
