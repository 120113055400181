import React, { Component } from "react";
import Keypad from "../Keypad";
import LogoutConfirmation from "../LogoutConfirmation";
import NavBar from "../../Navbar";

import "./TicketKeypad.css";

class TicketKeypad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      logoutConfirmation: false
    };
  }

  onSend(event, number) {
    if (number === null || number === undefined || number.trim().length === 0) {
      this.setState({
        loading: false,
        error: { message: "Please enter a ticket number" }
      });
      event.preventDefault();
      return;
    }

    this.setState({ loading: true });
    const api = this.props.rwtApi;
    api
      .getTab(number)
      .then(result => this.handleOpenTabSuccess(result))
      .catch(error => this.handleOpenTabError(error));
    event.preventDefault();
  }

  handleOpenTabSuccess(result) {
    this.setState({ loading: false });
    this.props.onTabOpened(result.data);
  }

  handleOpenTabError(e) {
    const error = { message: null };
    if (e.response) {
      const status = e.response.status;
      const data = e.response.data;
      if (status === 404) {
        error.message = data.message
          ? data.message
          : "Number error - Please try again";
      } else if (status === 500) {
        error.message = data.message
          ? data.message
          : "Server error - Please try again";
      } else if (status === 401) {
        //force a sign out
        this.props.onSignOut();
        return;
      }
    }

    if (error.message === null) {
      error.message = "An error has occurred - Please try again";
    }

    this.setState({ loading: false, error: error });
  }

  getKeypayLabel() {
    if (this.state.error === null) {
      return "Enter ticket number";
    } else {
      return this.state.error.message;
    }
  }

  getKeypadLabelClassName() {
    let cssClasses = "font-weight-normal keypad-label";
    if (this.state.error !== null) {
      cssClasses += " keypad-label-error";
    }

    return cssClasses;
  }

  getDisplayNumberClassName() {
    let cssClasses = "form-control display-number";
    if (this.state.error !== null) {
      cssClasses += " display-number-error";
    }

    return cssClasses;
  }

  keypadNumberClicked(value) {
    if (this.state.error) {
      this.setState({ error: null });
    }
  }

  keypadBackspaceClicked() {
    if (this.state.error) {
      this.setState({ error: null });
    }
  }

  keypadDeleteClicked() {
    if (this.state.error) {
      this.setState({ error: null });
    }
  }

  render() {
    const logoutConfirmation = this.state.logoutConfirmation;
    return (
      <React.Fragment>
        <NavBar name={this.props.loggedInName}>
          <button
            className="btn btn-navbar-right sign-out-btn"
            onClick={() => this.setState({ logoutConfirmation: true })}
          >
            Sign Out
          </button>
        </NavBar>
        <Keypad
          id="ticketKeypad"
          label={{
            text: this.getKeypayLabel(),
            className: this.getKeypadLabelClassName()
          }}
          display={{
            type: "text",
            className: this.getDisplayNumberClassName(),
            placeholder: "12345"
          }}
          loading={this.state.loading}
          sendButton={{
            text: "Open",
            onClick: (e, num) => this.onSend(e, num)
          }}
          keypadNumberClicked={value => this.keypadNumberClicked(value)}
          keypadDeleteClicked={() => this.keypadDeleteClicked()}
          keypadBackspaceClicked={() => this.keypadBackspaceClicked()}
          error={this.state.error}
        />
        {logoutConfirmation && (
          <LogoutConfirmation
            id="logoutConfirmation"
            logoutConfirmed={e => this.props.onSignOut(e)}
            onModalDismiss={() => this.setState({ logoutConfirmation: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default TicketKeypad;
