import * as constants from "../constants";
import axios from "axios";

class RwtApi {
	_token = null;

	constructor(token) {
		this._token = token;
	}

	login(user, password) {
		const serverURL = constants.SERVER_URL();
		return axios.post(
			`${serverURL}/rwt/login`,
			{user: user, password: password},
			{
				headers: {
					"Accept-Version": "1.0.0"
				}
			}
		).catch(it => {
			if (!it.response) constants.REMOVE_ENDPOINT(serverURL);
			else throw it
		});
	}

	loginWithCode(code) {
		const serverURL = constants.SERVER_URL();
		return axios.post(
			`${serverURL}/rwt/login/code`,
			{code: code},
			{
				headers: {
					"Accept-Version": "1.0.0"
				}
			}
		).catch(it => {
			if (!it.response) constants.REMOVE_ENDPOINT(serverURL);
			else throw it
		});
	}

	getMenu() {
		const serverURL = constants.SERVER_URL();
		return axios.get(`${serverURL}/rwt/menu`, {
			headers: {
				"Accept-Version": "1.0.0",
				"X-Auth-Token": this._token
			}
		}).catch(it => {
			if (!it.response) constants.REMOVE_ENDPOINT(serverURL);
			else throw it
		});
	}

	getTab(tabNumber) {
		const serverURL = constants.SERVER_URL();
		return axios.get(`${serverURL}/rwt/tab/${tabNumber}`, {
			headers: {
				"Accept-Version": "1.0.0",
				"X-Auth-Token": this._token
			}
		}).catch(it => {
			if (!it.response) constants.REMOVE_ENDPOINT(serverURL);
			else throw it
		});
	}

	sendItems(tabNumber, items) {
		const serverURL = constants.SERVER_URL();
		return axios.post(
			`${serverURL}/rwt/tab/${tabNumber}/items`,
			{commands: items},
			{
				headers: {
					"Accept-Version": "1.0.0",
					"X-Auth-Token": this._token
				}
			}
		).catch(it => {
			if (!it.response) constants.REMOVE_ENDPOINT(serverURL);
			else throw it
		});
	}
}

export default RwtApi;
